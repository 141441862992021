<template>
	<div class="termsArea">
		<b-row>
			<b-col sm="2" cols="0"/>
			<b-col sm="8" cols="auto">
				<div class="termsContent m-4 p-5 text-center">
					<!-- HEADER -->
					<b-img src="@/assets/img/manyhand.logo.png" center class="mb-3" alt="ManyBusyHands manybusyhands mbh MBH logo Logo" width="440" height="80"/>
					<h3 class="mt-1 mb-2">Website Terms and Conditions</h3>
					<hr>
					<p>This website is owned and operated by Geoffrey Frank Quinn, trading as ‘ManyBusyHands’ (ABN 49 328 354 227). 
						By using our Website and the Services and information offered on our Website, you are agreeing to these Terms.
						Please read these Terms carefully. If you do not agree to be bound by all of these Terms you must not use or 
						sign up to any Electronic Service and must cease accessing or using the Website immediately.
					</p>
					<ol type="1" class="text-left">
						<!-- #1 Definitions -->
						<h3><li>Definitions and Interpretation</li></h3>
						<b>In these Terms:</b>
						<ol type="a">
							<li>
								<b>Electronic Service(s)</b> means any service offered through this Website,
								electronic newsletters, information services or other means (such as 
								advertisements);
							</li>
							<li><b>Terms</b> means these terms and conditions of use of the Website;</li>
							<li><b>Website</b> means the website available at <router-link :to="{ name: 'Landing'}">www.manybusyhands.com</router-link>;</li>
							<li>
								a reference to “we”, “us” or “our” means Geoffrey Frank Quinn, trading as ‘ManyBusyHands’ 
								ABN 49 328 354 227 and any affiliates, employees, officers, agents or assigns;
							</li>
							<li>headings are for convenience only and do not affect interpretation; and</li>
							<li>
								the singular includes the plural and vice versa and words such as “including” 
								and similar expressions are not words of limitation.
							</li>
						</ol>
						<br>

						<!-- #2 Accuracy of Content -->
						<h3><li>Accuracy of Content</li></h3>
						<p class="mt-2 mb-2">
							We have taken proper care and precautions to ensure that the information we provide 
							on this Website is accurate. However, we cannot guarantee, nor do we accept any legal 
							liability arising from or connected to the accuracy, reliability, currency or 
							completeness of anything contained on this Website.
						</p>
						<p>
							We do not endorse the content submitted by you or other users to the Website, 
							including the Electronic Services, and do not guarantee the accuracy and reliability 
							of information submitted by visitors using our Electronic Services.
						</p>
						<br>

						<!-- #3 Use -->
						<h3><li>Use</li></h3>
						<p class="mt-3 mb-3">
							You agree that you will use this Website in accordance with all applicable local, 
							state, national and international laws, rules and regulations. 
						</p>
						<p>
							You agree that, unless expressly stated otherwise, our Electronic Services are provided 
							solely for your personal, non-commercial use. You agree that you will not use, nor will 
							you allow or authorise any third party to use the Website for any purpose that is unlawful, 
							defamatory, harassing, abusive, fraudulent or obscene or in any other inappropriate way or in a way 
							which conflicts with the Website or the Electronic Services.  
						</p>
						<p class="mt-3 mb-3">
							If you make any public comments on this Website which are, in our opinion, unlawful, defamatory, 
							harassing, abusive, fraudulent or obscene or in any other way inappropriate or which conflict with 
							the Website or the Electronic Services offered, then we may, at our absolute discretion, refuse to 
							publish such comments and/or remove them from the Website.
						</p>
						<p>
							Any link you make to an Electronic Service must be in a way that presents the Electronic Service as 
							we intended. You must not directly link to, frame or manipulate the material contained in an Electronic 
							Service or disguise its origin or ownership. 
						</p>
						<p class="mt-3 mb-3">
							You must not collect any personal information, email addresses or other electronic addresses provided 
							in or accessible through an Electronic Service for use in another database or otherwise. You must not 
							interfere with, or disrupt, the Website or the servers or networks connected to the Website, including by 
							transmitting any works, viruses, spyware, malware or any other code of a destructive or disruptive nature. 
							You must not change, modify, adapt or alter the Website or change, modify or alter another website so as to 
							falsely imply that it is associated with our Website. 
						</p>
						<p>
							We reserve the right to refuse or terminate the provision of our Electronic Services to anyone at any time 
							in breach of this clause without prior notice.
						</p>
						<br>
						
						<!-- #4 Intellectual Property -->
						<h3><li>Intellectual Property and Copyright</li></h3>
						<p class="mt-3 mb-3">
							We hold copyright and all other intellectual property in the content of this Website including, without 
							limitation, all uploaded files, layout design, data, graphics, articles, file, media, content, codes, news, 
							tutorials, videos, reviews, forum posts and data bases contained on the Website from time to time or used in 
							connection with the information, trademarks, trade names, logos and other material provided through our Electronic 
							Services. You must not use, replicate or licence our intellectual property for commercial purposes unless expressly 
							agreed to by us. 
						</p>
						<br>
						
						<!-- #5 Links to Ext Websites -->
						<h3><li>Links to External Websites</li></h3>
						<p class="mt-3 mb-3">
							This Website may contain links that direct you outside of this Website. These links are provided for your 
							convenience and are not an express or implied indication that we endorse the content of the linked website, its 
							content or any associated website, product or service. We accept no liability for any loss or damage arising out of 
							or in connection to your use of these third party websites. 
						</p>
						<p>We do not publish comments or feedback that includes and promotes links to external websites.</p>
						<p class="mt-3 mb-3">
							If you believe we are linking to any website or material on a website that infringes the rights of a third 
							party (including copyright), please notify us immediately. 
						</p>
						<br>

						<!-- #6 Liability -->
						<h3><li>Limitation of Liability</li></h3>
						<p class="mt-3 mb-3">
							To the fullest extent permissible by law, we take no responsibility for the accuracy of the content contained 
							on this Website or in relation to our Electronic Services. Information or statements provided through our Electronic 
							Services are made by way of general comment only and you should satisfy yourself as to their accuracy. Further, all 
							of our Electronic Services are provided without a warranty with the exception of any warranties provided by law. We 
							are not liable for any damages or loss whatsoever incurred as a result of, or relating to, the use of the Website or 
							our Electronic Services. Additionally, we are not liable in any circumstances for any delays or interruptions in the 
							provision of our Electronic Services.  
						</p>
						<p>
							At any time, and without notice or liability, we may change the content, format or method of delivery of our Electronic 
							Services, withdraw any material or services provided or refuse to allow any user or third party access to our Electronic 
							Services. 
						</p>
						<br>
						
						<!-- #7 Imdenification -->
						<h3><li>Indemnification for Loss or Damage</li></h3>
						<p class="mt-3 mb-3">
							You agree to indemnify and hold us harmless from and against any and all liabilities or expenses arising from or in any 
							way related to your use of this Website or the Electronic Services, including any direct or indirect liability or expense 
							arising from all claims, losses, damages (actual and consequential), suits, judgments, litigation costs and solicitors 
							fees of every kind and nature incurred by you or any third parties through you.  
						</p>
						<br>
						
						<!-- #8 Privacy -->
						<h3><li>Privacy and Confidentiality</li></h3>
						<p class="mt-3 mb-3">
							We may need to collect personal information about you in order to provide you with our Electronic Services. In certain 
							circumstances, we may disclose your personal information to third parties, such as contractors, third party business partners, 
							information technology suppliers and communication suppliers or as otherwise required by law to help deliver our Electronic 
							Services. 
						</p>
						<p>You agree to receive communications from us regarding our articles, activities, events, campaigns and goods and services.</p>
						<p class="mt-3 mb-3">
							As a small business, we are exempt from the operation of the Privacy Act 1988 (Cth), however we take your privacy seriously and 
							will undertake all reasonable endeavours to ensure that personal information you provide to us will be kept confidential and dealt 
							with in a confidential manner.  
						</p>
						<br>
						
						<!-- #9 Confidentiality -->
						<h3><li>Miscellaneous</li></h3>
						<p class="mt-3 mb-3">
							These Terms are governed by and construed in accordance with the laws of New South Wales, Australia. Any disputes 
							concerning this website are to be bound by the courts having jurisdiction in New South Wales.
						</p>
						<p>
							We retain the right to bring proceedings against you for breach of these Terms, in your country of residence or any other 
							appropriate jurisdiction. We do not waive a right, power or remedy available pursuant to these Terms if we fail to exercise 
							or delay in exercising the right, power or remedy. 
						</p>
						<p class="mt-3 mb-3">
							If any part of these Terms becomes void or unenforceable for any reason then that part will be severed with the intent 
							that all remaining parts will continue to have full force and effect. 
						</p>
						<p>
							No rule of construction applies to our disadvantage because we were responsible for preparing, or seek to rely on, these 
							Terms or any part of them.  
						</p>
						<p class="mt-3 mb-3">
							We may change these Terms from time to time without providing notice to you, by posting an updated 
							version of the Terms on our Website. Prior to using our Website you should ensure that you have reviewed and are aware of the 
							terms and conditions of use contained in these Terms, including any amendments. By continuing to use the Website you agree to 
							the terms and conditions of use contained in these Terms as amended from time to time.  
						</p>
						<br>
						
					</ol>
				</div>
			</b-col>
			<b-col sm="2" cols="0"/>
		</b-row>	
	</div>
</template>
<script>
export default {
  name: "TermsAndConditions",
};
</script>
