<template>
    <div class="payment-screen">
        <b-row></b-row>
        <br>
        <br>
        <br>
        <!-- TITLE -->
        <b-row>
            <h1 class="mx-auto">ManyBusyHands Website Payment Prototype</h1>
            <br>
        </b-row>
        <b-row>
            <p class="mx-auto">Our Payment Functionality is under construction.</p>
        </b-row>
        <br>
        <br>
        <br>
        <!-- PAYMENT BUTTON -->
        <b-row>
            <b-button to="/Search" class="mx-auto" variant="success">SUBMIT PAYMENT</b-button>
        </b-row>
        <br>
        <br>
        <br>
        
    </div>
    
</template>
<script>
/* Please note this page has been mocked as the functionality was out of scope for this project phase
 *
 */
export default {
    name: 'JobSeekerPayment',
};
</script>