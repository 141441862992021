<template>
    <div>
        <!-- TITLE SECTION -->
        <section class="hero-section-top about-us-header">
            <span class="background-image" role="img" aria-label="Backpackers - Students - Seasonal Harvest Work"></span>
            <div class="text-center text-section text-secondary" >
                <h1 class="page-header p-3 about-us-heading orange">About Us</h1>
            </div>
        </section>
        <!-- CONTENT -->
        <section class="about-us-section">
            <b-container class="pb-5">
                <b-row>
                    <b-col md="1" sm="0"/>
                    <!-- ABOUT MBH -->
                    <b-col md="11" class="about-us-text">
                        <div class="about-us-img-container">
                            <img src="@/assets/img/manyhand.logo.png" class="about-us-logo"/>
                        </div>
                        <div>
                            <p>
                                As a job seeker - whether a Grey Nomad, an Australian checking out their country, 
                                a guest in our country as a working holidaymaker (backpacker) or as an international 
                                student travelling across regional Australia - there is an enormous variety of work 
                                for you to experience, from which you will gather fantastic memories and gain 
                                lifelong skills. 
                            </p>
                            <p>
                                The variety of work available to you through ManyBusyHands runs across a spectrum ranging 
                                from aquaculture, whether it is pearls off the northwest coast, riverine oysters in 
                                New South Wales, salmon farms in Tasmania, land-based fish farms producing iconic native 
                                fish, Barramundi or Murray Cod, to Zoos and our wildlife conservation. Assisting people 
                                and their businesses still recovering from the terrible bushfires and now floods.
                            </p>
                            <p>
                                If your work is harvesting vegetables, picking fruit, carting grain, working with livestock, 
                                it is all part of the amazing mosaic of Australian agriculture. You will be playing an 
                                important part in the production system to produce quality food and fibre used to make 
                                the clothes you wear, as well as the wholesome, nutritious, delicious food you eat. 
                            </p>
                            <p>
                                As much of our production is exported to many parts of the world, know that your work 
                                will be appreciated and those you work for will appreciate you and be thankful for your efforts.  
                            </p>
                            <p>
                                As you travel across regional Australia, you will meet and work with some amazing people. 
                                Our hope is that you make some lifelong Australian friends. Take time from work to rest and relax.
                            </p>
                            <p>
                                When you return home, you will have our thanks for your contribution to our regional economy. 
                                When you walk the supermarket aisles in your hometown, seek out and buy quality Australian produce.
                            </p>
                        </div>

                        <!-- OUR GOALS -->
                        <br>
                        <h1 class="about-us-heading">Our Mission</h1>
                        <p>
                            ManyBusyHands’ aim is to help rural and regional businesses, to improve their supply chain efficiency. 
                            To highlight their excellence. 
                        </p>
                        <p>
                            Improve the flow of casual seasonal workers to rural and regional workplaces across Australia so we 
                            get the right workers in the right place at the right time. 
                        </p>
                        <p>
                            Greater workplace efficiency facilitates producers and businesses to get their produce, products and 
                            services to market and our exports to the world. Greater efficiency ensures people live affordably. 
                        </p>
                        <p>
                            Assist Jobseekers to use either their existing skills or to develop new lifelong skills. 
                            To find safe, well-rewarded work free from harassment. This benefits everyone and creates harmony. 
                        </p>
                        <p>
                            Importantly, for young guests to see and to experience, as you work and travel, the very best that 
                            we as rural and regional Australians have to offer. Make lifelong Australian bush friendships. 
                        </p>
                        
                        <div class="about-us-img-container">
                            <img src="@/assets/img/Me in meeting room 17 Feb 2020.jpeg" class="about-us-img"/>
                            <p class="about-us-img-caption">
                                Geoff Quinn, Founder of ManyBusyHands
                            </p>
                        </div>
                    </b-col>
                    <b-col md="1" sm="0"/>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
export default {
    name: 'AboutUs'
};
</script>